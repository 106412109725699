.layout {
  min-height: 100vh;
  overflow: hidden;
  padding: 0 !important;
  transition: all ease .1ms;
}

.layout.darkmode {
  background-color: #17212b;
}

.navBar {
  position: -webkit-sticky;
  position: sticky;
  width: 100%;
  top: 0;
  z-index: 100;
  backdrop-filter: blur(6px);
}

.darkmode .navBar {
  background-color: rgba(3, 15, 28, 0.6);
  color: white;
}

.navlink {
  display: flex;
  justify-content: flex-end;
  width: 40%;
  flex: 1;
}

.navlink a {
  color: inherit;
  text-decoration: none;
  margin: 0 15px;
  font-weight: 600;
  font-size: 18px;
}


/* .navBar-darkmode a {
  text-decoration: none;
  font-weight: 400;
  font-size: 1.1rem;
  color: white;
} */

.nav-brand-bar a[href='/']:first-child {
  font-size: 25px;
  font-weight: bold;
  line-height: normal;
  text-decoration: none;
  color: inherit;
}

.nav-brand-bar {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.nav-brand-bar>div:nth-child(2) {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 50%;
}

.modeToggle {
  background-color: transparent;
  transition: background-color ease-in-out .2s, color ease-in-out .2s;
  margin: 0 15px;
  border: none;
  border-radius: .4rem;
  height: 2.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modeToggle.darkmode {
  color: #62d4f7;
}

.modeToggle.darkmode:hover:before {
  content: attr(data-name);
  position: absolute;
  bottom: -2em;
  right: .6em;
  background-color: #626264;
  color: white;
  padding: .1rem .3rem;
  display: inline-block;
  font-size: .9rem;
  border-radius: .3125rem;

}

.modeToggle:hover {
  background-color: #185ba3;
}

/* .dropdown.darkmode>div:nth-child(1) {
  width: 100%;
} */

.dropdown>div:nth-child(1) .nav_link {
  display: flex;
  flex-direction: column;
}

.dropdown>div:nth-child(2) {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 10%;
  position: relative;
}

.dropdown {
  display: none;
}

.toggleBurger {
  display: none;
}

.darkmode .navlink a::after {
  background-color: white;
}


@media (max-width:768px) {

  .navlink {
    display: none;
  }

  .toggleBurger {
    display: block;
    background-color: transparent;
    border: 0;
    transition: all ease .1s;
    height: 2.8rem;
    border-radius: .4rem;
  }

  .darkmode.toggleBurger {
    color: #62d4f7;
  }

  .darkmode .toggleBurger:hover {
    background-color: #185ba3;
  }

  .togglebtn-darkmode,
  .toggleBurger,
  .navBar-darkmode section a:first-of-type {
    display: inline-block;
  }

  .darkmode .togglebtn:hover {
    background-color: rgba(248, 246, 249, 0.2);
  }

  .dropdown {
    display: flex;
    position: absolute !important;
    text-decoration: none;
    z-index: 100;
    backdrop-filter: blur(50px);
    left: 0;
    right: 0;
    width: 100%;
    height: 22rem;
    transition: top ease-in-out 500ms, background-color ease-in-out 500ms, color ease-in-out 500ms;
  }

  .darkmode .dropdown {
    background-color: rgba(1, 7, 12, .9);
  }

  .dropdown>div {
    width: 100%;
  }

  .nav_link {
    display: flex;
    flex-direction: column;
  }

  .dropdown.active {
    top: 0;
  }

  .dropdown.inactive {
    top: -500px;
  }

  .nav_link a {
    margin: 40px 0 !important;
    text-decoration: none;
    width: 100%;
    text-align: center;
    border-radius: .2em;
    color: white !important;
    padding: .3em;
    background-color: transparent;
    text-decoration: none;
  }

  .dropdown a {
    transition: all ease .1ms;
  }

  .dropdown.darkmode a:hover {
    text-decoration: underline;
    background-color: rgba(233, 238, 243, 0.1);
  }

  .togglebtn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: background-color ease-in-out .2s;
    height: 2.8rem;
    position: absolute;
    top: 12px;
    right: 2.5px;
    background-color: transparent;
    border: none;
    border-radius: .4em;
    padding: .2rem;
    margin: 0 2rem;
    font-size: 1.2rem;
  }

  .darkmode .togglebtn {
    color: white;
  }

  .darkmode .dropdown button:hover {
    background-color: rgba(248, 246, 249, 0.2);
  }

  .inactive {
    top: -500px !important;
  }

}



/* Homepage */

.darkmode .hero-section {
  color: white;
}

.hero-content {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4rem;
}

.hero-content>div:first-of-type {
  width: 23%;
  height: 15rem;
  margin: 0 .3rem;
  position: relative;
  top: 0;
}

.hero-content>div:last-of-type {
  width: 40%;
  margin: 0 .3rem;
}

.hero-content>div:first-of-type img {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 3%;
  top: 0;
}

.darkmode .hero-content h2 {
  color: #548a98;
}

.hero-content h2 {
  padding: 0 .23em;
  font-weight: 500;
}

.hero-content p {
  font-size: 1.3rem;
  font-weight: lighter;
  padding: 0 .7em;
}

.darkmode .header-section p {
  color: rgb(237, 231, 231);
}

.darkmode .header-container h2 {
  color: #548a98;
}

@media (max-width:992px) {
  .hero-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5rem 0;
  }

  .hero-content>div:first-of-type {
    width: 30%;
    height: 12rem;
    margin-bottom: 1rem;
  }

  .hero-content>div:last-of-type {
    text-align: center;
    width: 80%;
  }

  img {
    width: 100%;
    height: 100%;
    border-radius: 3%;
  }

}

@media (min-width:550px) {

  .header-container h2,
  .hero-content h2 {
    font-size: 4rem;
  }
}

@media (max-width:550px) {

  .header-container h2,
  .hero-content h2 {
    font-size: 3rem;
  }

  .hero-content>div:first-of-type {
    width: 50%;
    height: 12rem;
  }

  .hero-content>div:last-of-type {
    text-align: center;
    width: 80%;
  }

  img {
    width: 100%;
    height: 100%;
    border-radius: 3%;
  }
}

.introduction-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 3rem;
}

.introduction-container {
  width: 70%;
  color: white;
}

.descriptionbar-container {
  height: 1rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top-right-radius: .8rem;
  border-top-left-radius: .8rem;
  padding: .9rem;
  font-weight: bolder;
  transition: all ease .1ms;
}

.descriptionbar-container>span:first-of-type svg {
  margin: 0 .2em;
  border-radius: 50%;
}

.descriptionbar-container>span:first-of-type svg:first-of-type {
  background-color: #f86a6a;
  color: #f86a6a;
}

.descriptionbar-container>span:first-of-type svg:nth-of-type(2) {
  background-color: orange;
  color: orange;
}

.descriptionbar-container>span:first-of-type svg:nth-of-type(3) {
  background-color: green;
  color: green;
}

.darkmode .descriptionbar-container {
  background-color: rgb(34, 34, 34);
}

.selfdescription-section {
  overflow: hidden;
  white-space: pre-line;
  height: 37rem;
  padding: .7rem .9em;
  font-size: 1.17rem;
  border-radius: .8rem;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  transition: all ease .1ms;
}

.darkmode .selfdescription-section {
  background-color: rgba(82, 80, 80, 0.4);
  color: rgb(201, 198, 198);
}

.icon {
  color: rgba(171, 255, 46, .6);
  font-weight: 500;
  font-size: 1.3rem;
}

.selfdescription-section {
  font-weight: bold;
}


/* projects page */

.projectdescription-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  position: relative;
}

.projectdescription-container {
  border-radius: .7rem;
  height: 100%;
  margin: 3rem 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all ease .1ms;
}

.projectdescriptionbarstyle {
  height: 1rem;
  padding: 5px 3px;
}


.projectdetail-imagecontainer img {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 0;
  border-bottom-left-radius: .4em;
  border-bottom-right-radius: .4em;
}

.projectinfo-section {
  padding: 15px 10px;
  height: 13rem;
  display: flex;
  position: absolute;
  flex-direction: column;
  overflow: hidden;
  cursor: pointer;
  transition: all ease-in-out .1ms;
}

.projectinfo-container {
  text-decoration: none;
  color: inherit;
}

.darkmode .projectdescription-container:nth-of-type(2n) {
  position: relative;
  background: linear-gradient(45deg, #548a98, #19262c, #627484);
}

.darkmode .projectdescription-container:nth-of-type(2n-1) {
  position: relative;
  background: linear-gradient(45deg, #627484, #19262c, #548a98);
}

.darkmode .projectinfo-section {
  background-color: rgb(3, 15, 28);
  color: white;
}

.darkmode .projectinfo-section:hover {
  background-color: rgba(3, 15, 28, .8);
}

.projectinfo-container {
  max-width: 100%;
}

.projectinfo-section p {
  margin: 0;
  font-weight: lighter;
  padding: .3em 0;
  font-size: 16px;
  line-height: normal;
}

.projectinfo-section h1 {
  display: inline;
  font-weight: bold;
  font-size: 1.5rem;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-wrap: nowrap;
  text-overflow: ellipsis;
}

.project-link {
  font-size: 1.3rem;
  color: #6da3b1;
  transition: color ease-in-out 200ms;
}

.projectinfo-section:hover .project-link {
  color: rgba(109, 163, 177, .9);
}

.projectinfo-container>div:nth-of-type(1) {
  display: flex;
  flex-direction: row;
  align-items: normal;
  justify-content: space-between;
  margin-bottom: .3rem;
}

.projectinfo-container>div:nth-of-type(2) {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.projectinfo-section span {
  border-radius: .4rem;
  padding: .2em .5em;
  font-size: 1em;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 .1em;
}

.projectinfo-container>div:nth-of-type(2) span:nth-of-type(1) {
  background-color: #E44D26;
}

.projectinfo-container>div:nth-of-type(2) span:nth-of-type(2) {
  background-color: #2965f1;
}

.projectinfo-container>div:nth-of-type(2) span:nth-of-type(3) {
  background-color: #f0db4f;
}

@media (min-width: 992px) {
  .projectdescription-container {
    width: 55% !important;
    padding: 5em;
    border-radius: 1rem;
    position: relative;
  }

  .projectdescription-container:nth-of-type(2n) {
    right: 5%;
  }

  .projectdescription-container:nth-of-type(2n-1) {
    left: 5%;
  }

  .projectdescription-container:nth-of-type(2n-1) .projectinfo-section {
    transform: translateX(10%);
    transform: translateY(-50%);
    left: -35%;
    top: 50%;
  }

  .projectdescription-container:nth-of-type(2n) .projectinfo-section {
    transform: translateX(-10%);
    transform: translateY(-50%);
    right: -35%;
    top: 50%;
  }

  .inner-description {
    position: relative;
    width: 100%;
  }

  .projectinfo-section {
    width: 50%;
    overflow: hidden;
    border-radius: 1rem;
    height: 40%;
  }
}


@media (max-width: 992px) {

  .descriptionbar-container {
    display: flex;
    align-items: center;
  }

  .projectdescription-container {
    transform: translateX(0);
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    margin: 9rem 0;
    width: 90%;
    padding: 4em 3em;
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .projectdescription-container:first-of-type {
    margin-top: 2rem;
    margin-bottom: 9rem
  }

  .projectdescription-container:last-of-type {
    margin-bottom: 18rem;
  }

  .projectdetail-section img {
    width: 100%;
  }

  .projectinfo-section {
    border-bottom-left-radius: 0.7rem;
    border-bottom-right-radius: 0.7rem;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    position: absolute;
    bottom: -13em;
    left: 0;
    width: 100%;
  }

}



/* repo page */

.repo-section a {
  padding: 1.3rem;
  width: 40%;
  border-radius: .5rem;
  transition: all ease .1s;
  color: white;
  text-align: center;
  text-decoration: none;
}

.repo-section {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0;
}

.darkmode .repo-section a {
  background-color: rgb(3, 15, 28);
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: white;
}

.darkmode .repo-section a:hover {
  transform: translateY(-5px);
  box-shadow: 0px 1px 3px -1px rgb(245, 245, 249);
}

.repo-section a span:first-of-type {
  font-size: 1.5rem;
  font-weight: bold;
  display: block;
}

/* footer section */


.darkmode .footer-section>div:last-child a svg {
  color: #050a0f;
}

.darkmode .footer-section {
  background-color: rgb(3, 15, 28);
  color: rgba(255, 255, 255, 0.7);
}

.darkmode .footer-section a svg {
  background-color: rgba(255, 255, 255, 0.7);
}

.darkmode .footer-section>div:last-child a:hover::before {
  background-color: #626264;
  color: #fff;
}

@media(min-width: 768px) {

  .darkmode .repo-section a {
    margin: 1.5rem;
  }

}

@media(max-width: 768px) {

  .introduction-section {
    display: none;
  }

  .repo-section {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 2.5rem;
  }

  .repo-section a {
    width: 100%;
    margin: 1rem 0;
  }

}