.layout.lightmode {
    background-color: rgb(182, 178, 178);
}

.lightmode .navBar {
    color: #000;
    background-color: rgba(65, 66, 66, 0.6);
}


.lightmode .modeToggle {
    color: inherit;
}

.lightmode .modeToggle:hover:before {
    content: attr(data-name);
    position: absolute;
    bottom: -2em;
    right: .6em;
    background-color: #626264;
    color: white;
    padding: .1rem .3rem;
    display: inline-block;
    font-size: .9rem;
    border-radius: .3125rem;

}

.lightmode .modeToggle:hover {
    background-color: #3b3b3c;
    color: white
}

.lightmode .toggleBurger {
    color: inherit;
}

.lightmode .toggleBurger:hover {
    color: white;
    background-color: #3b3b3c;
}

.lightmode .navlink a::after {
    background-color: black;
}

@media (max-width:768px) {

    .togglebtn-lightmode,
    .canceltogglebtn-lightmode,
    .lightmode .navBar section a:first-of-type {
        display: inline-block;
    }

    .lightmode .togglebtn {
        color: black;
    }

    .lightmode .togglebtn:hover {
        background-color: #3b3b3c;
    }

    .lightmode .dropdown {
        background-color: rgba(38, 39, 39, 0.9);
    }

    .lightmode .dropdown {
        top: 0;
    }

    .lightmode .dropdown a:hover {
        text-decoration: underline;
        background-color: rgba(233, 238, 243, 0.1);
    }

    .dropdown-lightmode button {
        align-self: flex-end;
        background-color: transparent;
        border: none;
        border-radius: .4em;
        padding: .2rem;
        color: white;
        margin: 0 2rem;
        font-size: 1.2rem;
        transition: background-color ease-in-out 0.2s;
    }

    .dropdown-lightmode button:hover {
        background-color: rgba(248, 246, 249, 0.2);
    }

    .span-links {
        display: none;
    }

}

img {
    width: 100%;
    height: 100%;
    border-radius: 3%;
    object-fit: cover;
}

.header-container {
    text-align: center;
    padding: 2em 0;
}

.lightmode .hero-section {
    color: black;
}

.lightmode .header-section h2 {
    color: black;
}

.lightmode .header-section p {
    color: rgb(35, 34, 34);
}

.header-container p {
    font-size: 1.3rem;
    font-weight: lighter;
    padding: 0 .7em;
}

.lightmode .descriptionbar-container {
    background-color: rgb(134, 131, 131)
}

.lightmode .descriptionbar-container {
    color: black;
}

.lightmode .selfdescription-section {
    background-color: rgba(71, 69, 69, 0.7);
}

.lightmode .selfdescription-section {
    color: rgb(27, 25, 25);
}

/* projects page */

.lightmode .projectinfo-section {
    background-color: white;
}

.lightmode .projectdescription-container:nth-of-type(2n) {
    background: linear-gradient(45deg, #548a98, #19262c, #627484);
}

.lightmode .projectdescription-container:nth-of-type(2n-1) {
    background: linear-gradient(45deg, #627484, #19262c, #548a98);
}


.lightmode .projectinfo-section {
    color: black;
}

.lightmode .projectinfo-section:hover {
    background-color: rgba(255, 255, 255, .8);
}


/* repo page */

.lightmode .repo-section a {
    background-color: white;
    border: 1px solid rgba(44, 43, 43, 0.6);
    color: black;
}

.lightmode .repo-section a:hover {
    transform: translateY(-5px);
    box-shadow: 0px 1px 3px -1px rgb(24, 24, 24);
}


/* footer section */

.footer-section {
    margin: 0% !important;
    position: relative;
    display: flex;
    justify-content: space-evenly;
    padding: 1em;
    align-items: center;
    transition: all ease .1ms;
}

.lightmode .footer-section {
    background-color: rgb(26, 27, 27);
    color: rgba(255, 255, 255, 0.7);
}

.lightmode .footer-section a svg {
    background-color: rgba(255, 255, 255, 0.7);
}

.footer-section a svg {
    border-radius: 50%;
    padding: .2rem;
    margin: 0 .6rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer-section>div:last-child a:hover::before {
    content: attr(data-name);
    position: absolute;
    top: -20px;
    border-radius: .3125rem;
    display: inline-block;
    font-size: .9rem;
    padding: .08rem .3rem;
}

.lightmode .footer-section>div:last-child a:hover::before {
    background-color: #414143;
    color: #fff;
}

.footer-section>div {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.lightmode .footer-section>div:last-child a svg {
    color: #212121;
}

@media(min-width: 768px) {

    .lightmode .repo-section a {
        margin: 1.5rem;
    }

    .footer-section {
        flex-direction: row;
    }

}

@media(max-width: 768px) {
    .footer-section {
        flex-direction: column;
        gap: 10px;
    }

}